import { HeaderIcon } from './headerIcon'
import './layout.scss'

export default function CommonHeader(){
    return (
        <div className='headerModule p_fixed flex align_center justify_between bd_a_c_5_1'>
            <a href="/"><img className='mainLogo' src='/images/logo.svg' /></a>
            <HeaderIcon className="hide_s" />
        </div>
    )
}