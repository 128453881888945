import { useRef, useLayoutEffect } from "react"
import { Modal } from "antd"
import  './index.scss'
/**
 *
 * @param props.title {str}
 * @param props.footer {div}
 * @param props.header {div}
 * @param props.nopadding {div} content(props.children)
 * @param bodyClass {String} overwrite body style
 * @param headerClass {String} overwrite header style
 * @param autoScrollTop boolean always back to ViewModal Top
 * @param modalHeight set modal height
 * @param titleCenter set modal title center
 * @param titleClass set modal title class
 * @returns
 */
const ViewModal = (props) => {


    return <ViewModalCover {...props}>
                <ViewModalContent {...props} />
            </ViewModalCover>
}


const ViewModalCover = (props) => {
    return (
        <Modal
        {...props}
        styles={{"body":{padding:0,borderRadius: 0,backgroundColor:'transparent',}}}
        title={null}
        footer={null}
        centered={true}
        closable={false}
        className={null}
        wrapClassName={null}
        >
            {
                props.children
            }
        </Modal>
    )
}

const ViewModalContent = (props) => {
    const scrollRef = useRef()
    useLayoutEffect(() => {
        if (props.autoScrollTop) {
            scrollRef?.current?.scrollTop > 0 && scrollRef.current.scrollTo({ top: 0 })
        }
    }, [props.visible])

    return (
        <div className="view_modal modal_bg br_24 flex column w100 p_relative" style={{maxHeight:props.modalHeight}}>
            {
                props.header
                ?   <div className="view_modal_header p_relative">
                        {props.header}
                        {props.closeNative ? 
                            <div className='modal_close p_absolute flex align_center cursor pa_12' onClick={props.onCancel}>
                                <span className='btn_c_2 icon p-icon-close1 is_20'>X</span>
                            </div> : ""}
                    </div>
                :   <></>
            }
            {/* ${props.nopadding&&'no_padding'} */}
            <div ref={scrollRef} className={`modal_content overflow_auto ${props.bodyClass}` }>
                {props.children}
            </div>

            { props.footer || <></> }
        </div>
    )
}


ViewModal.Cover = ViewModalCover
ViewModal.Content = ViewModalContent


export default ViewModal
