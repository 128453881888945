
const dictMethod = {

    getChain(wallet,chain,field){

        if(!wallet || !Array.isArray(wallet.usableChainArray) || !chain){
            return
        }

        let chainInfo = null

        if(typeof chain === 'number'){
            chainInfo = wallet.usableChainArray.find(val=>val.chainId===chain)
        }
        if(typeof chain === 'string'){
            chainInfo = wallet.usableChainArray.find(val=>val.networkAlias===chain)
        }

        if(field && typeof field==='string'){
            return chainInfo[field]
        }
        return chainInfo;
    },

}

export default dictMethod
