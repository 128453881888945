
import { useEffect } from 'react';
import { withRouter,useHistory,useLocation } from 'react-router-dom';



const RouterBefore = ({ routerList, children}) => {

    return children

}





export default withRouter(RouterBefore);
