const StringMethod = {


    /*
    * @describe Shorten string length
    * @param Int [beforeLen]
    * @param Int [afterLen]
    * @param String [adorn]
    * return String
    */
    encrypt(value,beforeLen=6,afterLen=4,adorn="..."){
        value = value ? value.toString() : "";
        if(!value || value.length < beforeLen + afterLen){
            return value.toString();
        }
        return value.substring(0,beforeLen) + adorn + value.substring(value.length,value.length - afterLen)
    },

    /*
    * @describe remove left and right empty strings
    * @param String [str]
    * return String
    */ 
    trimSpace(str){
        str = str + '';
        return str.replace(/\s+/g,'');
    },

    trimStr(str,specify=" ",dir='l'){
        if(str.length===0) return str;
        let snippet = str.charAt(str.length-1);

        if(snippet===specify){
            let re = str.substring(0,str.length-1);
            return re;
        }
        return str;
    },

    /*
     * @describe Generate 64-bit strings , add timestamp
     * @param Int [length]
     * return String
     * str="abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
     */
    randomUniqueString(length,prefix=''){

        let str="abcdef0123456789"
        let res = [];
        let ctime = new Date().getTime().toString();

        for(let i=0; i < length; i++ ) {
            if(res.length < ctime.length){
                res.push( str[ctime[i]] );
            }else{
                let bit = parseInt(Math.random() * 1000);
                bit = bit >= 16 ? bit % 10 : bit;
                res.push( str[bit] );
            }
        }

        return prefix + res.join('');
    },

    /*
     * @Delete the illegal characters in the int type
     * @str String
     * return String
     */
    keepstr(str,type='int'){

        let regEx = /^0+|[^\d]/g;
        str = str.toString() || '';
        str = str.replace(regEx, '');
        return str;
    },

    bintToString(val){

        if(typeof val === 'bigint') return val.toString()

        if(Array.isArray(val)){
            for(let i=0; i<val.length; i++){
                val[i] = this.bintToString(val[i])
            }
        }

        if(typeof val === 'object'){
            for(let item in val){
                val[item] = this.bintToString(val[item])
            }
            return val
        }

        return val
    }
}

export default StringMethod
