import * as math from './math';

const sort = {

    selection(arr,field,orderby='asc'){
        let i,j,t,before,after;
        for(i=0; i<arr.length; i++){
            for(j=i+1; j<arr.length; j++){
                if(field){
                    before = arr[i][field] || 0
                    after = arr[j][field] || 0
                }else{
                    before = arr[i]
                    after = arr[j]
                }
                if(orderby==='asc'){
                    if(math.gt(before,after)){
                        t = arr[i];
                        arr[i] = arr[j];
                        arr[j] = t;
                    }
                }
                if(orderby==='desc'){
                    if(math.lt(before,after)){
                        t = arr[i];
                        arr[i] = arr[j];
                        arr[j] = t;
                    }
                }
            }
        }
        return arr;
    },

    bubble(arr,field,orderby='asc'){
        let i,j,t,before,after;
        for(i=0; i<arr.length-1; i++){
            for(j=0; j<arr.length-1-i; j++){
                if(field){
                    before = arr[j][field] || 0
                    after = arr[j+1][field] || 0
                }else{
                    before = arr[j]
                    after = arr[j+1]
                }
                if(orderby==='asc'){
                    if(math.gt(before,after)){
                        t = arr[j];
                        arr[j] = arr[j+1];
                        arr[j+1] = t;
                    }
                }
                if(orderby==='desc'){
                    if(math.lt(before,after)){
                        t = arr[j];
                        arr[j] = arr[j+1];
                        arr[j+1] = t;
                    }
                }
            }
        }
        return arr;
    }

}

export default sort
