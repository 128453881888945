import React from 'react'

const router = [
    {
        path: '/',
        component: React.lazy(() => import('../../views/home')),
        exact: true,
        version: 1,
        auth: true,
    },
    {
        path: '/tge',
        component: React.lazy(() => import('../../views/tge')),
        exact: true,
        version: 1,
        auth: true,
    },
    {
        path: '/404',
        component: React.lazy(() => import('../../views/layout/error')),
        exact: true,
        version: 1
    }
]

export default router;
