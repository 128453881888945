
import validate from './validate'
import { div,toFixed } from './math'

export function fillZore(v){
    return v >= 10 ? v+'' : '0' + v;
}



function toDateCase(dateInfo){

    if(!dateInfo) return null;

    if(dateInfo instanceof Date) return dateInfo

    if(validate.isNumber(dateInfo)){
        const times = dateInfo.toString()
        if(times.length === 10 || times.length === 11) return new Date(times*1000)
        if(times.length >= 13) return new Date(parseInt(times))
    }

    if(typeof dateInfo === 'string'){
        const times = new Date(dateInfo)
        if(times.toString() !== 'Invalid Date') return times
    }

    return null;

}

function delRepeat(arr){

    if(arr.length<2){
        return arr;
    }

    let newArr = [];
    let record;
    arr.forEach((v,i)=>{
        if(v===record){
            newArr[newArr.length-1] = newArr[newArr.length-1]+v.toString();
        }else{
            record = v;
            newArr.push(v);
        }
    })
    return newArr;
}

function toEnDate(index){
    const list = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ]
    return list[index-1];
}

function transferInfo(type,date){

    switch(type){
        case 'yy':
            return date[0].substring(2,4);
        case 'y':
            return date[0];
        case 'm':
            return date[1];
        case 'M':
            return toEnDate(date[1]);
        case 'd':
            return date[2];
        case 'H':
            return date[3];
        case 'h':
            return date[3] > 12 ? ((date[3] - 12)>=10 ? (date[3] - 12) : "0" + (date[3] - 12)) : date[3];
        case 'I':
            return date[4];
        case 'i':
            return date[4];
        case 'S':
            return date[5];
        case 's':
            return date[5];
        case 'P':
            return date[3] > 12 ?  "PM" : "AM";
        case 'p':
            return date[3] > 12 ?  "pm" : "am";
        case 'u':
            const utc = new Date().getTimezoneOffset()/60;
            return "UTC" + (utc>=0?"-":"+") + Math.abs(utc);
        case ' ':
            return " ";
        case ',':
            return ",";
        case '/':
            return "/";
        case '\\':
            return "\\";
        case '*':
            return "*";
        case '#':
            return "#";
        case '%':
            return "%";
        case '|':
            return "|";
        case '-':
            return "-";
        case ':':
            return ":";
        default:
            return ''
    }
}

function dateInfo(date){
    let y = fillZore(date.getFullYear());
    let m = fillZore(date.getMonth() + 1);
    let d = fillZore(date.getDate());
    let h = fillZore(date.getHours());
    let i = fillZore(date.getMinutes());
    let s = fillZore(date.getSeconds());
    return [y,m,d,h,i,s];
}

// returns date string
export function toJson(dateInfo){
    const dates = toDateCase(dateInfo || new Date()) //str ? new Date(str) : new Date()
    const times = {
        y: dates.getFullYear(),
        m: dates.getMonth()+1,
        d: dates.getDate(),
        w: dates.getDay(),
        h: dates.getHours(),
        i: dates.getMinutes(),
        s: dates.getSeconds(),
    }
    return times
}

// Returns the specified date format
export function getFormatDate(date,formatType){

    if(!formatType) return date;

    const typeArray = delRepeat(formatType.split(''));
    const dateInfoArray = dateInfo(date)

    let joinDate = [];
    typeArray.forEach(type=>{
        joinDate.push( transferInfo(type,dateInfoArray) );
    })

    return joinDate.join('');

}

/**
 * @description: returns the number of milliseconds in the start time of the specified type
 * @param {*} n offset day
 * @param {*} timeStamp
 * @return {*}
 */
export function startPeriodTime(type,dateInfo){

    dateInfo = toDateCase(dateInfo || new Date())
    const timezoneOffset = new Date().getTimezoneOffset()*60000
    const t = dateInfo.getTime()
    const times = {
        '1m': t - t%(60000*1) - timezoneOffset,
        '5m': t - t%(60000*5) - timezoneOffset,
        '10m': t - t%(60000*10) - timezoneOffset,
        '30m': t - t%(60000*30) - timezoneOffset,
        '1h': t - t%(60000*60) - timezoneOffset,
        '2h': t - t%(60000*120) - timezoneOffset,
        '12h': t - t%(60000*720) - timezoneOffset,
        '1d': t - t%(86400000*1),
        '1w': parseInt((t+3*86400000)/(7*86400000))*(7*86400000) - 3*86400000,
        '2w': parseInt((t+3*86400000)/(14*86400000))*(14*86400000) - 3*86400000,
        '1M': new Date(`${dateInfo.getFullYear()}-${dateInfo.getMonth()<9?'0':''}${dateInfo.getMonth()+1}-01`).getTime()
    }
    return times[type]
}



/**
 * @description: the total days of the last month for the specified date
 * @param {*} dateInfo  timeStamp or Date
 * @param {*} n offset month -/+
 * @return {*}
 */
export function monthDays(n=0,dateInfo){

    dateInfo = toDateCase(dateInfo || new Date())

    let month = dateInfo.getMonth()+n+1;
    let year = dateInfo.getFullYear();

    let lastDate = new Date(year,month,0);
    return lastDate.getDate();
}

/**
 * @description:
 * @param {*} n offset day
 * @param {*} timeStamp
 * @return {*}
 */
export function toDays(n=0,dateInfo){

    dateInfo = toDateCase(dateInfo || new Date())
    const specMonth = dateInfo.setDate(dateInfo.getDate()+n)
    return specMonth

};

/**
 * @description:
 * @param {*} dateInfo  timeStamp or Date
 * @param {*} n offset month -/+
 * @return {*} timeStamp
 */
export function toMonths(n=0,dateInfo){

    dateInfo = toDateCase(dateInfo || new Date())
    const specMonth = dateInfo.setMonth(dateInfo.getMonth()+n)
    return specMonth

};

/**
 * @description:
 * @param {*} dateInfo  timeStamp or Date
 * @param {*} n offset month -/+
 * @return {*} timeStamp
 */
export function toDates(n=1,type='1d',dateInfo){

    dateInfo = toDateCase(dateInfo || new Date())

    const extend = {
        '1m': 60000*1,
        '5m': 60000*5,
        '10m': 60000*10,
        '30m': 60000*30,
        '1h': 60000*60,
        '2h': 60000*120,
        '12h': 60000*720,
        '1d': 86400000*1,
        '1w': 86400000*7,
        '2w': 86400000*14,
    }

    if(type==='1M') return toMonths(n,dateInfo)
    return dateInfo.getTime()+extend[type]*n

};


/**
 * @description:
 * @param {*} type
 * @param {*} dateInfo [Date,timeStamp 10/13, identification]
 * @return {*}
 */
 export function format(dateInfo,type){

    const datecase = toDateCase(dateInfo);
    if(!datecase || !type){
        return dateInfo;
    }

    return getFormatDate(datecase,type);

};



/**
 * @description:
 * @param {Int} seconds time second
 * @param {String} suffix s/S
 * @param {String} formatType d,[h,[i,[s]]]
 * @param {Int} len 1-10
 * @return {String} time info
 */
export function duration(seconds,suffix='s',formatType='dhi',len=10){
    
    const times = {}
    times.d = seconds>=86400 ? parseInt(seconds / 86400) : 0;
    times.h = seconds>=3600 ? parseInt(div(seconds % 86400,3600)) : 0;
    times.i = seconds>=60 ? parseInt(div(seconds % 3600,60)) : 0;
    times.s = parseInt(seconds % 60)


    const short = {
        y: ['Y','Y'],
        m: ['M','M'],
        d: ['D','D'],
        h: ["H",'H'],
        i: ["Min",'Min'],
        s: ["S","S"]
    }
    const long = {
        y: [' Year',' Years'],
        m: [' Month',' Months'],
        d: [' Day',' Days'],
        h: [" Hour",' Hours'],
        i: [" Minute",' Minutes'],
        s: [" Second"," Seconds"]
    }

    suffix = suffix === 's' ? short : long
    const arr = []

    formatType.split('').forEach(val=>{
        if(times[val]){
            let timeSuffix = times[val]>1?suffix[val][1]:suffix[val][0]
            arr.push(times[val]+timeSuffix)
        }
    })

    return arr.splice(0,len).join(' ')
}


/**
 * @description:
 * @param {Int|Date|String} end end time
 * @param {Int|Date|String} start start time
 * @param {String} suffix s/S
 * @param {String} formatType d,[h,[i,[s]]]
 * @param {Int} len 1-10
 * @return {String} time info
 */
export function overplus(end,start,suffix,formatType,len){
    

    let t1 = toDateCase(end||new Date());
    let t2 = toDateCase(start||new Date());

    if(!t1 || !t2) return null;

    const secondLeft = (t1.getTime() - t2.getTime())/1000

    if(secondLeft<=0) return 0;

    return duration(secondLeft,suffix,formatType,len)
    

}





