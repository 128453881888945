import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch,Redirect } from 'react-router-dom';
import RouterBefore from './routerBefore';
import CommonHeader from '../views/layout/header';
import CommonFooter from '../views/layout/footer';



import router from './routerConfig'


const AppRouter = () => {


    return (
        <Router>
            <Suspense fallback={<></>}>
                <RouterBefore routerList={router}>
                    <CommonHeader/>
                    <div className="page">
                        <Switch>

                            {
                                router.map((route, key) =>
                                    <Route
                                        exact={route.exact }
                                        key={key}
                                        path={route.path}
                                        component={route.component} >
                                        {
                                            route.routes && route.routes.map( (item, index) =>
                                                <Route key={index} exact={item.exact } path={item.path} component={item.component} />
                                            )
                                        }

                                    </Route>
                                )
                            }

                            <Redirect path="/" exact to="/" />
                            <Route path='*' component={React.lazy(() => import('../views/layout/error'))} />
                        </Switch>
                    </div>
                    <CommonFooter />
                </RouterBefore>                
            </Suspense>
        </Router>
    )
}

export default AppRouter;
