import { math,validate } from '@/utils';

const ArrayMethod = {

    arrayToObject(list,key){
        if(!list || !Array.isArray(list) || !key){
            return {}
        }

        let obj = {}
        list.forEach(val=>{
            let keyName = val[key]
            obj[keyName] = val;
        })
        return obj;
    },

    arrayMerge(){

        if(arguments.length<3){
            // The parameters are incorrect:
            return []
        }

        let keys=[],lists = []
        for(let i=0; i<arguments.length; i++){
            if(!Array.isArray(arguments[i])){
                // Incorrect parameter, not a valid array:
                return []
            }
            if(i===0) {
                keys = arguments[i]
            }else{
                lists.push(arguments[i]);
            }
        }

        let result = []
        for(let i=0; i<lists[0].length; i++){
            for(let j=0; j<lists.length; j++){
                result[i] = result[i] ? result[i] : {}
                result[i][keys[j]] = lists[j][i]
            }
        }
        return result;
    },

    // {key1:'add',key2: 'sub'}
    arraySum(list,keysObj,condition){

        list.forEach(val=>{
            Object.keys(keysObj).forEach(k=>{
                let o = validate.isNumber(keysObj[k]) ? keysObj[k] : 0;
                let n = validate.isNumber(val[k]) ? val[k] : 0;
                keysObj[k] = math.add(o , n)
            })
        })
        return keysObj;
    },

    distinct(list,key){
        let arr = []
        let arrObj = []
        list.forEach(val => {
            if (arr.indexOf(val[key]) === -1 ) {
                arr.push(val[key])
                arrObj.push(val);
            }
        })
        return arrObj
    },

    tree(list,level=0){


    }
}

export default ArrayMethod
