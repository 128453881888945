
import validate from './validate';
import common from './common';
// import storage from './storage'
import * as fdate from './formatDate'
import * as math from './math';
import sort from './sort';
import array from './array';
import string from './string';
import dict from './dict';
// import * as contractUtils from './contractUtils'

export {
    common,
    validate,
    math,
    fdate,
    sort,
    array,
    string,
    // storage,
    dict,
    // contractUtils
}


window.utils = {
    common,
    validate,
    math,
    fdate,
    sort,
    array,
    string,
    // storage,
    dict,
    // contractUtils
};
