import { Decimal } from 'decimal.js';
import validate from './validate'

Decimal.set({ precision: 40, toExpNeg: -40, toExpPos: 40 });

export function min() {

    for(let i=0; i<arguments.length; i++){
        if(!validate.isNumber(arguments[i])){
            return 0;
        }
    }
    return Decimal.min.apply(Decimal,arguments).valueOf();
}

export function max() {
    for(let i=0; i<arguments.length; i++){
        if(!validate.isNumber(arguments[i])){
            return 0;
        }
    }
    return Decimal.max.apply(Decimal,arguments).valueOf();
}

export function sum() {
    if(arguments.length===0) return 0;
    for(let i=0; i<arguments.length; i++){
        if(!validate.isNumber(arguments[i])){
            return 0;
        }
    }
    return Decimal.sum.apply(Decimal,arguments).valueOf();
}

export function sub(v1, v2) {
    if(!validate.isNumber(v1) || !validate.isNumber(v2)){
        return 0;
    }
    return Decimal.sub(v1, v2).toString();
}

export function plus(v1, v2) {
    if(!validate.isNumber(v1) || !validate.isNumber(v2)){
        return 0;
    }
    return Decimal.add(v1, v2).valueOf();
}

export function add(v1,v2){
    if(!validate.isNumber(v1) || !validate.isNumber(v2)){
        return 0;
    }
    return Decimal.add(v1, v2).valueOf();
}

export function mul(v1, v2) {
    if(!validate.isNumber(v1) || !validate.isNumber(v2)){
        return 0;
    }
    return Decimal.mul(v1, v2).valueOf();
}

export function div(v1, v2) {
    if(
        v2 === 0 ||
        v2 === '0' ||
        !validate.isNumber(v1) ||
        !validate.isNumber(v2)
    ){
        return 0;
    }
    return Decimal.div(v1, v2).valueOf();
}

export function lt(v1, v2) {
    if(!validate.isNumber(v1) || !validate.isNumber(v2)){
      return false;
    }
    return Decimal(v1).lt(v2);
}

export function lte(v1, v2) {
    if(!validate.isNumber(v1) || !validate.isNumber(v2)){
      return false;
    }
    return Decimal(v1).lte(v2);
}

export function gt(v1, v2) {
    if(!validate.isNumber(v1) || !validate.isNumber(v2)){
        return false;
    }
    return Decimal(v1).gt(v2);
}

export function gte(v1, v2) {
    if(!validate.isNumber(v1) || !validate.isNumber(v2)){
        return false;
    }
    return Decimal(v1).gte(v2);
}

export function eq(v1, v2) {
    if(!validate.isNumber(v1) || !validate.isNumber(v2)){
        return false;
    }
    return Decimal(v1).eq(v2);
}

export function pow(x,y){
    return Decimal.pow(x,y).valueOf();
}

export function abs(v){
    return Decimal.abs(v).valueOf();
}

export function ceil(num){
    return Decimal.ceil(num).valueOf();
}

export function floor(num){
    return Decimal.floor(num).valueOf();
}

export function fromWei(num, decimal = 18) {
    num = num || '0';
    const p = pow(10, decimal)
    const l = div(num, p);
    return l.valueOf();
}

export function toWei(num, decimal = 18,float="floor") {
    if(!validate.isNumber(num)){
        return 0;
    }
    num = toFixed(num,decimal,float);
    const p = pow(10, decimal)
    const l = mul(num, p);
    return l.valueOf();
}



export function toNumber(val) {
    return Decimal.min(val).toNumber();
}

export function toFixedString(num,len){

    if(!validate.isNumber(num)){
        return num;
    }

    const strArr = num.toString().split(".");
    strArr[1] = (strArr[1]||'')+"00000000000000000";
    strArr[1] = strArr[1].substring(0,len);

    return strArr.join(".");
}

export function toFixed(num, dec = 18,type) {
    if(!validate.isNumber(num)){
        return num;
    }
    let round;
    switch(type){
        case 'ROUND_FLOOR':
        case 'floor': round = Decimal.ROUND_FLOOR; break;
        case 'ROUND_CEIL':
        case 'ceil': round = Decimal.ROUND_CEIL; break;
        case 'ROUND_DOWN':
        case 'down': round = Decimal.ROUND_DOWN; break;
        case 'ROUND_UP':
        case 'up': round = Decimal.ROUND_UP; break;
        case 'ROUND_HALF_UP':
        case 'halfUp': round = Decimal.ROUND_HALF_UP; break;
        case 'ROUND_HALF_DOWN':
        case 'halfDown': round = Decimal.ROUND_HALF_DOWN; break;
        default: round = Decimal.ROUND_FLOOR;
    }

    return mul(new Decimal(num).toFixed(Number(dec||0),round),1);
}

// export function toFixed(num, dec = 18,type='floor') {
//     if(!num){return num}
//     const p = pow(10,dec);
//     const l = mul(num,p);
//     const c = type==='floor' ? floor(l) : ceil(l);
//     const m = div(c,p);
//     return m;
// }

export function toThousand(num){
    if(!validate.isNumber(num,"float")){
      return num;
    }
      let result = num.toString().replace(/\d+/, function(n){
          return n.replace(/(\d)(?=(\d{3})+$)/g,function($1){
             return $1+",";
          });
     })
     return result;
}

export function toLargeSymbol(num,arg){

    if(!validate.isNumber(num)){
        return num;
    }

    let negative = false;
    if(num < 0){
        negative = true;
        num = mul(num,-1);
    }

    const symbol = [
        { label: "B", value: 1000000000 },
        { label: "M", value: 1000000 },
        { label: "K", value: 1000 }
    ]

    let res = {
        label: '',
        value: num
    }

    for(let i=0; i<symbol.length; i++){
        let v = symbol[i];
        if(num >= v.value){
            res.value = div(num,v.value);
            res.label = v.label
            break;
        }
    }

    return {
        value: negative ? mul(res.value,-1) : res.value,
        label: res.label
    }
}

export function toExponential(value){

    if(value==='0' || !value) return 0;

    const Dec = Decimal(value);

    if(value >= 1){
        if( Dec.isInteger() ){
            return Dec.toString()
        }
        return parseFloat( Dec.toFixed(6, Decimal.ROUND_DOWN) );
    }

    if(Dec.e < -3){
        return Dec.toExponential(6, Decimal.ROUND_DOWN);
    }

    return parseFloat( Dec.toFixed(6, Decimal.ROUND_DOWN) );
}

export function toFormat(num,decimal=2,thousand=true,largeSymbol=true){

    if(!validate.isNumber(num)){
        return num;
    }

    let symbolObj = largeSymbol ? toLargeSymbol(num) : {value:num,label:''};

    num = symbolObj.value
    num = toFixedString(num,decimal,)
    num = thousand ? toThousand(num) : num

    return num + symbolObj.label;
}

export function decLen(v1){
    if(!validate.isNumber(v1)){
        return 0;
    }
    v1 = v1.toString().split('.')
    return v1[1] ? v1[1].length : 0;
}














////////////////////////////////////////

const testExprRegex = /^[\d+(\.\d+)?\s\(\)\+\-\*\/\>\<\^\=\!]+$/;
const powRegex = /(-?\d+(?:\.\d+)?)\s*\^\s*(-?\d+(?:\.\d+)?)/;
const mulDivRegex = /(-?\d+(?:\.\d+)?)\s*([*/])\s*(-?\d+(?:\.\d+)?)/;
const addSubRegex = /(-?\d+(?:\.\d+)?)\s*([+\-])\s*(-?\d+(?:\.\d+)?)/;
const compareRegex = /(-?\d+(?:\.\d+)?)\s*(>=|>|<=|<|===|!==)\s*(-?\d+(?:\.\d+)?)/;

function expressionToMath(expression) {
  let expr = expression;

  const parseAndReplace = (regex, handler) => {
    while (regex.test(expr)) {
      expr = expr.replace(regex, handler);
    }
  };

  parseAndReplace(/\(([^\(\)]*)\)/g, (match, p1) => parseExpr(p1));
  parseAndReplace(powRegex, (match, p1, p2) => pow(p1, p2));
  parseAndReplace(mulDivRegex, (match, p1, op, p3) => (op === '*' ? mul(p1, p3) : div(p1, p3)));
  parseAndReplace(addSubRegex, (match, p1, op, p3) => (op === '+' ? add(p1, p3) : sub(p1, p3)));
  parseAndReplace(compareRegex, (match, p1, op, p3) => {
    const operations = {
      '>=': gte,
      '>': gt,
      '<=': lte,
      '<': lt,
      '===': eq,
      '!==': (x, y) => !eq(x, y)
    };
    return operations[op](p1, p3);
  });

  if (expr === 'false') expr = false;
  if (expr === 'true') expr = true;
  return expr;
}

export function parseExpr(expression) {
  try {
    let str = expression.replace(/\s/g, '');
    if (str.includes('e')) {
      str = expression.replace(/e/g, Decimal.exp(1).valueOf());
    }
    if (testExprRegex.test(str)) {
      return expressionToMath(str);
    } else {
      throw new Error(`illegal expression ${expression}`);
    }
  } catch (err) {
    return '0';
  }
}

