/*****
 * Splice browser links for the specified chain,
 *
 * ******/
const ChainMethod = {

    // Returns the token address of the specified chain
    toBrowserToken(chainObjectInfo,token){
        return this.toBrowser(chainObjectInfo,token,'/token')
    },

    // Returns the account address of the specified chain
    toBrowserAccount(chainObjectInfo,account){
        return this.toBrowser(chainObjectInfo,account,'/address')
    },

    // Returns the transaction hash address of the specified chain
    toBrowserHash(chainObjectInfo,hash){
        return this.toBrowser(chainObjectInfo,hash,'/tx')
    },

    // Returns the transaction block address of the specified chain
    toBrowserBlock(chainObjectInfo,block){
        return this.toBrowser(chainObjectInfo,block,'/block')
    },

    toBrowser(chainObjectInfo,str,type) {

        if(!chainObjectInfo){
            return "11";
        }

        if(!str){
            return "11";
        }

        return chainObjectInfo.explorerUrl + type + "/" + str;
    }

}




const common = {

    ...ChainMethod,

    /*
     * @describe Insert loading dialog in body
     * @param String [htmlStr]
     * return String
     */
    loading2(htmlStr){
        if(htmlStr !== false){
            let mask = "<div class='loading'><div class='loader-07'></div></div>";
            document.getElementById("mask").innerHTML = mask;
            return;
        }
        document.getElementById("mask").innerHTML = '';
    },
    loading(v){

        const loading = document.querySelector("#lo_loading");

        if(v != false){
            loading.setAttribute("class","active");
            return;
        }

        loading.removeAttribute("class");

    },

    /*
     * @describe get the state corresponding to the current url
     * @param Array [listArray]
     * return listArray[]{}value
     */
    getHistoryInfo(listArray){
        let index = "1";
        listArray.forEach((val,i)=>{
            let browser = window.location.pathname.toLowerCase();
            let current = val.link.toLowerCase();
            if(browser.indexOf(current)>=0){
                index = val.value;
            }
        })
        return index;
    },

    /*
     * @describe: Finds the specified item, based on the value of the array
     * @param Array [listArray]
     * return listArray[]{}value
     */
    getArrayItem(listArr,valArr,key='value'){
        const arr = []

        valArr.forEach(val=>{
            const obj = listArr.find(item=>item[key]===val)
            if(obj) arr.push(obj)
        })
        return arr;
    }

}

export default common;




