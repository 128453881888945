import { useCallback, useMemo, useRef, useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import './index.scss'
import { useEffect } from 'react';
import { math,validate,string } from '@/utils'


/**
 * input -
 * @Attributes value <String> defalut value
 * @Attributes type <String> int/float/[text]
 * @Attributes size <String> mini/small/medium/large/huge
 * @Attributes dec <Int> decimals
 * @Attributes maxValue <String>
 * @Attributes minValue <String>
 * @Attributes focus <String>
 * @Attributes thousands <Boolean>
 * @Attributes disabled <Boolean>
 * @Attributes placeholder <String>
 * @Attributes maxLength <Int>
 * @Attributes clear <Boolean>
 * @Attributes style <CssNode>
 * @Attributes border <Boolean>  default true
 * @Attributes errTip <String>
 * @Node addonBefore <JsxNode>
 * @Node addonAfter <JsxNode>
 * @Event onChange <Function> native event
 * @Event onFocus/onBlur/onKeyDown/onClick <Function> native event
 */

const InputBox = (props)=>{


    const inputElement = useRef(null);
    const [currentValue,setCurrentValue] = useState('')

    useEffect(()=>{
        if(!props.focus) return;
        inputElement.current.focus()
    },[props])

    const focus = (ele)=>{
        props.onFocus && props.onFocus(ele.target.value,ele)
    }

    const blur = (ele)=>{
        props.onBlur && props.onBlur(ele.target.value,ele)
    }

    const click = (ele)=>{
        props.onClick && props.onClick(ele.target.value,ele)
    }

    const keyDown = (ele)=>{
        props.onKeyDown && processInputType('onKeyDown',ele.target.value,ele);
    }

    const keyUp = (ele)=>{
        if(ele.keyCode===13){
            props.onKeyEnter && props.onKeyEnter()
            return;
        }
        props.onKeyDown && processInputType('onKeyDown',ele.target.value,ele);
    }

    const clear = ()=>{
        inputElement.current.value = '';
        const ele = {
            target: inputElement.current
        };
        props.onChange && processInputType('onChange',ele.target.value,ele);
    }

    const change = (ele)=>{
        props.onChange && processInputType('onChange',ele.target.value,ele);
    }

    const processInputType = (eventName,inputValue,element)=>{

        if(inputValue==='') {
            // setCurrentValue(inputValue||'')
            props[eventName](inputValue,element);
            return;
        }

        inputValue = handleKeep(inputValue);
        inputValue = handleThousand(inputValue,'remove');
        inputValue = handleMinValue(inputValue);
        inputValue = handleMaxValue(inputValue);
        inputValue = handleType(inputValue);
        inputValue = handleDec(inputValue);

        // setCurrentValue(inputValue||'')
        props[eventName](inputValue,element)
        
    }

    const handleKeep = (value)=>{

        let type = props.type?.toLowerCase();

        if(!props.keep || !value) return value;
        if(['int','number'].indexOf(type) >= 0){
            return string.keepstr(value)
        }
        return  value;
    }

    const handleThousand = (value,type)=>{
        if(!props.thousands || !value ) return value;

        if(type==='add'){
            return math.toThousand(value);
        }
        if(type==='remove'){
            value = value.toString().replace(/,/g,'');
            return value;
        }
    }

    const handleMinValue = (value)=>{

        if(!value || !props.minValue || !validate.isUsableInput(value) || value[value.length-1]==='.'){
            return value;
        }

        return math.lt(value,props.minValue) ? props.minValue : value
    }

    const handleMaxValue = (value)=>{

        if(!value || !props.maxValue || !validate.isUsableInput(value) || value[value.length-1]==='.'){
            return value;
        }

        return math.gt(value,props.maxValue) ? props.maxValue : value
    }

    const handleType = (value) =>{

        let type = props.type?.toLowerCase();

        if(!value) return value;

        if(['int','number'].indexOf(type) >= 0){
            // validate.isNumber(tempValue,'int') ? tempValue : value;
            const tempValue = string.keepstr(value)
            return tempValue;
        }

        if(['float','price'].indexOf(type) >= 0){
            return validate.isUsableInput(value,30) ? value : props.value
        }
        return value
    }

    const handleDec = (value) =>{
        const dec = props.dec || 30
        if(['float','price'].indexOf(props.type) === -1) return value;
        if(!validate.isUsableInput(value,30)) return value;
        if(math.decLen(value) < dec) return value;
        if(math.decLen(value) < math.decLen(props.value)) return value;
        return math.toFixed(value,dec||18)
    }

    return (
        <div className={`inputBoxModule w100 ${props.wrapClass || ''}`}>
            <div className={`inputType flex align_center br_8 ${props.noborder ? '':'bd bd_c_3'} ${props.errTip ? 'bd_a_c_2':''} ${props.showbg ? 'b_g_4' : ''} ${props.size||'middle'} ${props.className}`}>
                {
                    props.addonBefore && <div className='flex align_center'>{props.addonBefore}</div>
                }
                <input
                    id="inputBox"
                    className={`px_24 ${props.disabled ? 'cursor_not': ''}`}
                    disabled={props.disabled ? true : false}
                    ref={inputElement}
                    type={ props.type || "text" }
                    readOnly={props.disabled}
                    placeholder={props.placeholder||''}
                    value={handleThousand(props.value,'add')}
                    style={props.style}
                    onKeyDown={e=>{keyDown(e)}}
                    onKeyUp={e=>{keyUp(e)}}
                    onFocus={e=>{focus(e)}}
                    onBlur={e=>{blur(e)}}
                    onClick={e=>{click(e)}}
                    onChange={e=>{change(e)}}
                    maxLength={props.maxLength}
                    autoComplete='off'
                    />
                {
                    props.clear && props.value &&
                    <div className="flex align_center justify_center text_3 is_24 icon p-a-icon-Errorclose cursor px_12" onClick={()=>clear()}></div>
                }
                {
                    props.addonAfter
                        ? <div className={`white_space_nowrap pr_8`}>{props.addonAfter}</div>
                    :   null
                }
            </div>
            {
                props.errTip &&
                <p className="pt_8 a_c_2"><CloseCircleOutlined />&nbsp;{props.errTip}</p>
            }


        </div>
    )

}



export default InputBox;

